@import '~@studyportals/styles-abstracts/abstracts.scss';

.FilterTag {
	display: inline-flex;
	gap: 0.5rem;
	align-items: center;
	width: fit-content;
	height: 2rem;
	background-color: $Grey-100;
	border-radius: 3px;
	cursor: pointer;

	@include PaddingAreas(0, 0.5);

	.FilterName {
		max-width: 206px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		@include TextStyle('Note');
	}
}

.FilterTag.Disabled {
	color: $Grey-600;
	cursor: unset;

	.ButtonIcon {
		display: none;
	}
}
