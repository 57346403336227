@import '~@studyportals/styles-abstracts/abstracts.scss';
@import '../../styles/styles.scss';

.LocationSwitcher {
	display: flex;
	justify-content: space-between;
	@include MarginAreas(1, 0, 0, 0);

	.CountryButton {
		height: 1rem;
		width: 3.5rem;
	}

	.ContinentButton {
		height: 1rem;
		width: 4rem;
	}
}

.CountryOptions {
	padding: 0;

	.CountryOption {
		display: flex;
		gap: 0.5rem;
		justify-content: left;
		margin: 0.5rem 0;

		.CountryCheckbox {
			height: 1rem;
			width: 1rem;
		}

		.CountryName {
			height: 1rem;
			width: 6rem;
		}
	}
}
