@import '~@studyportals/styles-abstracts/abstracts.scss';

.CheckboxInput {
	margin: 4px 0.4rem 0 0;
	width: 15px;
	height: 15px;
	border: 1px solid $Grey-600;
	border-radius: 2px;

	@include Breakpoint(Large, ExtraLarge) {
		width: 13px;
		height: 13px;
	}
}

.Facet {
	@include TextStyle('Note');
	@include MarginAreas(0.25, 0, 0, 0);
	color: $Grey-700;
	float: right;
}

.CheckboxRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.FacetContainer {
		display: flex;
		flex-direction: row;
	}

	.AreaSwitcher {
		@include TextStyle('Note');
		@include PaddingAreas(0);
		@include MarginAreas(0.25, 0, 0, 0.5);
		border: 0;
		cursor: pointer;
		background: transparent;
		float: right;

		.ToggleIcon {
			font-size: 1rem;
		}
		min-width: 1rem;
	}
}
