@import '~@studyportals/styles-abstracts/abstracts.scss';

.RefineContentOptionsList {
	position: relative;
}

.UniversityFilterLabel {
	display: inline-block;
	padding: 0 0.5rem 0.75rem 0;

	@include TextStyle(Body);
}
