@import '~@studyportals/styles-abstracts/abstracts.scss';

.SelectedFiltersContainer.Visible {
	gap: 1rem;
	@include PaddingAreas(1, 0, 0);
}

.SelectedFiltersContainer {
	display: flex;
	flex-direction: column;

	.SelectedFiltersHeader {
		display: flex;
		justify-content: space-between;

		@include TextStyle('Title100');
	}

	.FilterTagContainer {
		display: flex;
		flex-wrap: wrap;
		gap: 0.5rem;

		&.Collapsed {
			max-height: 4.5rem;
			overflow: hidden;
		}
	}

	.ShowMoreButton,
	.ShowLessButton,
	.ClearButton {
		width: fit-content;
		background-color: unset;
		border: none;
		cursor: pointer;

		@include TextStyle('Note');
	}

	.ButtonIcon {
		vertical-align: middle;
	}
}
