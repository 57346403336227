@import '~@studyportals/styles-abstracts/abstracts.scss';

.LocationFilterWrapper {
	.LocationSwitcher {
		@include TextStyle('Note');
		border-bottom: 1px solid $Grey-200;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		> * {
			@include PaddingAreas(0, 0, 0.5);
			@include TextStyle('Note');
			border: 0;
			cursor: pointer;
			background: transparent;
		}

		.is-active {
			@include TextStyle('Note');
			border-bottom: 1px solid $Blue-700;
			font-weight: bold;
			color: $Blue-700;
		}

		.CountrySwitcher,
		.ContinentSwitcher {
			&:hover {
				border-bottom: 1px solid $Blue-700;
				color: $Blue-700;
			}
		}
	}
}
