@import '~@studyportals/styles-abstracts/abstracts.scss';

.CountryFilterWrapper {
	@include MarginAreas(0);
	@include PaddingAreas(0);

	.AddCountry {
		@include PaddingAreas(0);
		color: $Blue-700;
		background: none;
		border: none;
		font: inherit;
		cursor: pointer;

		@include Breakpoint(Large, ExtraLarge) {
			@include MarginAreas(0, 0, 0.5, 0);
			@include PaddingAreas(0);
		}
	}

	.Indented {
		@include MarginAreas(0, 1.5, 0, 0);
	}
}
