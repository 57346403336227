@import '~@studyportals/styles-abstracts/abstracts.scss';
@import '~@studyportals/styles-components/components/TextInput';

.TuitionFeeFilterWrapper {
	.controlLabel {
		@include TextStyle(Note);
		@include MarginAreas(1, 0);

		a {
			cursor: pointer;
		}
	}

	.rangeContainer {
		display: flex;
		flex-direction: column;
		width: 100%;

		.sliderControl {
			position: relative;
			min-height: 1rem;
			@include MarginAreas(1.5, 0, 0);

			.slider {
				-webkit-appearance: none;
				appearance: none;
				height: 2px;
				width: 100%;
				position: absolute;
				background-color: $Blue-700;
				pointer-events: none;
			}

			.slider::-webkit-slider-thumb {
				-webkit-appearance: none;
				pointer-events: all;
				width: 16px;
				height: 16px;
				background-color: $Blue-700;
				border-radius: 50%;
				box-shadow: 0 0 0 1px $Blue-400;
				cursor: pointer;
			}

			.slider::-moz-range-thumb {
				pointer-events: all;
				width: 16px;
				height: 16px;
				background-color: $Blue-700;
				border-radius: 50%;
				box-shadow: 0 0 0 1px $Blue-400;
				cursor: pointer;
			}

			.slider::-webkit-slider-thumb:hover {
				background: $Blue-700;
			}

			.slider::-webkit-slider-thumb:active {
				box-shadow: inset 0 0 3px $Blue-400, 0 0 9px $Blue-400;
				-webkit-box-shadow: inset 0 0 3px $Blue-400, 0 0 9px $Blue-400;
			}

			.fromSlider {
				background-color: transparent;
				z-index: $FirstFloor;
			}
		}

		.formControl {
			position: relative;
			display: flex;
			justify-content: space-evenly;
			font-size: 16px;

			.SCTextInput {
				display: flex;
				flex-direction: column;
				width: 100%;

				label {
					top: 0;
					@include TextStyle(Note);
				}

				input {
					width: 100%;
				}
			}

			.SCTextInput:first-child {
				@include MarginAreas(0, 1, 0, 0);
			}
		}
	}

	.Currency {
		color: $Blue-700;
	}
}
