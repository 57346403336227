@import '@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/abstracts.scss';

$gradientDark: rgba(255, 255, 255, 0);
$gradientLight: rgba(255, 255, 255, 0.5);
$linearGradient: linear-gradient(100deg, $gradientDark 40%, $gradientLight 50%, $gradientDark 60%);

.Skeleton {
	border-radius: 5px;
	background: $linearGradient $Grey-100;
	background-size: 200% 100%;
	background-position-x: 120%;
	animation: 3s loading ease-in-out infinite;

	@keyframes loading {
		to {
			background-position-x: -10%;
		}
	}
}
