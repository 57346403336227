@import '~@studyportals/styles-abstracts/abstracts.scss';

.SearchFilterContainer > :first-child {
	@include Breakpoint(Large, ExtraLarge) {
		border-top: 4px solid $Orange-500;
		border-radius: 4px;
	}
}

.SearchFilterContainer {
	background-color: $White;
	color: $Grey-900;

	@include MarginAreas(1, 0);
	@include PaddingAreas(0, 0, 4, 0);

	@include Breakpoint(Large, ExtraLarge) {
		@include PaddingAreas(1);
	}

	ul {
		&::after {
			content: '';
			display: table;
			clear: both;
		}

		li {
			@include MarginAreas(0.25, 0);

			display: block;

			/* Prevent margin collapse */
			float: left;
			width: 100%;
		}
	}

	.RefineSectionHeading.MobileOnlyBlock {
		@include Breakpoint(Large, ExtraLarge) {
			display: none;
		}
	}

	.RefineSectionHeading {
		@include MarginAreas(0);

		@include PaddingAreas(1, 0, 0.5, 0);

		@include TextStyle('Title100');

		display: flex;
		align-items: center;
		background-color: $White;
		font-weight: bold;
		text-decoration: none;
		cursor: pointer;
		border-top: 1px solid $Grey-200;
		border-bottom: 1px solid $Grey-200;

		.Title {
			flex: 1;
		}

		.FilterIcon {
			@include TextStyle('BodyNarrow');

			@include MarginAreas(0, 0.5, 0, 0);
		}

		.Badge {
			@include MarginAreas(0, 0.5, 0, 0);
			@include TextStyle('Note');
			line-height: 1.5;
			width: 19px;
			height: 19px;
			padding: 1px;
			color: $White;
			background: $Blue-700;
			border-radius: 50%;
			text-align: center;
		}
	}

	.RefineContentOptionsList {
		@include PaddingAreas(0);

		@include MarginAreas(1, 0, 1, 0);
	}

	.is-collapsed {
		.RefineContentOptionsList {
			display: none;
		}
	}
}
