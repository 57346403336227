@import '~@studyportals/styles-abstracts/abstracts.scss';

.MainDiscipline {
	@include PaddingAreas(0.25, 0);
}

.SubDiscipline {
	@include PaddingAreas(0.25, 0, 0.25, 0.5);
	@include MarginAreas(0, 0, 0, 3);
	@include TextStyle('Note');
	border-left: 1px solid $Grey-200;

	@include Breakpoint(Large, ExtraLarge) {
		display: inline-flex;
	}
}

.is-active {
	border-left: 1px solid $Blue-700;
	background: $Grey-100;

	.DisciplineName {
		font-weight: bold;
	}
}

.DisciplineName {
	@include TextStyle('Note');
	display: inline-block;
	width: 10rem;
	text-overflow: ellipsis;
	/* Required for text-overflow to do something */
	overflow: hidden;
	white-space: nowrap;
}

.DisciplineIcon {
	@include MarginAreas(0, 0.5, 0, 0);
}

.Facet {
	@include TextStyle('Note');
	color: $Grey-700;
	float: right;
}
