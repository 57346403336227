@import '~@studyportals/styles-abstracts/abstracts.scss';

$width: 100%;
$height: 32px;
$padding-left-right: 0.75rem;
$padding-top-bottom: 0.25rem;
$number-of-suggestions: 6;

.FilterSearchBoxWrapper {
	position: relative;
	width: 100%;

	.FilterSearchBox {
		width: $width;
		height: $height;
		border: 1px solid $Grey-600;
		border-radius: 1px;
		outline: none;
		text-overflow: ellipsis;
		background-color: $White;

		@include PaddingAreas(0, 1.5, 0, 0.5);
		@include TextStyle(Body);
	}

	.FilterSearchBox::placeholder {
		color: $Grey-600;
	}

	.FilterSearchBox::-webkit-search-cancel-button {
		display: none;
	}

	.FilterSearchBoxIcon {
		position: absolute;
		left: calc($width - $padding-left-right * 2);
		top: 0.5rem;

		&.Clickable {
			cursor: pointer;
		}
	}
}

.FilterSuggestionsWrapper {
	position: absolute;
	width: $width;
	max-height: calc($height * $number-of-suggestions + $padding-top-bottom * $number-of-suggestions * 2);
	display: inline-block;
	text-align: left;
	box-shadow: 0 2px 2px 0 $Grey-300;
	border-radius: 2px;
	overflow-x: hidden;
	overflow-y: scroll;
	background-color: $White;

	@include TextStyle(Body);
	@include MarginAreas(0);

	.FilterSuggestion {
		width: calc($width - $padding-left-right * 2);
		height: $height;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		padding: $padding-top-bottom $padding-left-right;
		cursor: pointer;

		@include TextStyle(Body);
		@include MarginAreas(0);

		&:hover {
			background-color: $Grey-100;
		}
	}
}
