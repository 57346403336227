@import '~@studyportals/styles-abstracts/abstracts.scss';

.DisciplineFilterWrapper {
	.Discipline {
		@include MarginAreas(0);
		@include PaddingAreas(0);
		cursor: pointer;
	}

	.ShowMainDisciplines {
		@include MarginAreas(0, 1);
		@include PaddingAreas(0);
		color: $Blue-700;
		background: transparent;
		border: 0;
		cursor: pointer;
	}
}
