@import '../../styles/styles.scss';

.TagContainer {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
}

.Tag {
	height: 2rem;
	width: 6rem;
}
